import React from 'react'

const Message = ({ type, message }: { type: string; message: string }) => {
  return (
    <header className="alt message_container">
      <div className="message">
        <strong className={type}>{type}</strong>
        <span dangerouslySetInnerHTML={{ __html: `“ ${message} ”` }} />
      </div>
    </header>
  )
}

export default Message
