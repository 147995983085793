import React from 'react'
import { useSlack } from './hooks'
import Subscribe from './Subscribe'

const BannerLanding = () => {
  useSlack(`Subscribe`)
  return (
    <section id="banner" className="major">
      <div className="inner">
        <header className="major">
          <h1>Stay in the know</h1>
        </header>
        <div className="content">
          <Subscribe />
        </div>
      </div>
    </section>
  )
}

export default BannerLanding
