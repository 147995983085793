import React from 'react'
import Layout from '../components/layout'
import BannerSubscribe from '../components/BannerSubscribe'
import { Seo } from '../components/Seo'

const Landing = () => (
  <Layout>
    <Seo title="Subscribe" />
    <BannerSubscribe />
  </Layout>
)

export default Landing
