import React, { useState } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import Message from './Message'
import { handleWebHook } from '../utils'

const Subscribe = () => {
  const [formData, setFormData] = useState({ name: '', email: '' })
  const [message, setMessage] = useState({ type: '', text: '' })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { email, name } = formData
    addToMailchimp(email, { FNAME: name })
      .then(data => {
        handleWebHook({
          text: `👋 ${name} just subscribed using ${email} address!`,
          channel: '#general',
        })
        setMessage({
          ...message,
          type: data.result,
          text: data.msg,
        })
        setFormData({ ...formData, name: '', email: '' })
      })
      .catch((error: Error) => {
        // Errors in here are client side
        // Mailchimp always returns a 200
        console.error(error)
      })
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.currentTarget.value })
  }

  return (
    <>
      <section id="subscribe">
        <div className="inner">
          <section>
            <form onSubmit={handleSubmit}>
              <div className="field half first">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  autoComplete="off"
                  onChange={handleChange}
                  value={formData.name}
                />
              </div>
              <div className="field half">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  required
                  onChange={handleChange}
                  autoComplete="off"
                  value={formData.email}
                />
              </div>
              <ul className="actions">
                <li>
                  <input type="submit" value="Subscribe" className="special" />
                </li>
                <li>
                  <input type="reset" value="Clear" />
                </li>
              </ul>
            </form>
          </section>
          {message.text && (
            <Message type={message.type} message={message.text} />
          )}
        </div>
      </section>
    </>
  )
}

export default Subscribe
